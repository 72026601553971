import React from 'react';
import logo from './assets/icons/favicon.png';
import './App.scss';
import Clock from './components/clock';

function App() {
  const title = 'PlusTech';
  return (
    <div className='app'>
      <div className='title-bar'>
        <span style={{ paddingLeft: '20px' }}>
          <img className='logo' src={ logo } alt='logo'/>
        </span>
        <span className='title-logo'>{ title }<span className="title-version"></span></span>
        <span style={{ flex: '1 0' }}></span>
        <span className='title-time'><Clock /></span>
      </div>
      <div className='main-content'>
        网站施工中。。。
      </div>
      <div className='bottom-bar'>
        ICP:&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">津ICP备2022002270号-1</a>
      </div>
    </div>
  );
}

export default App;
